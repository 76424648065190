import React from "react";

function Submitted() {
  return (
    <>
      <div className="ccl-completed container">
        <h2>Thank you for WP Send to Employer:</h2>
        <p>
          Thank you for using Grapple to send your pdf version WP letter to
          employer.
        </p>
        <p>
          This will update the PD stage (if in ATJ) and will send a copy of the
          letter to the client too in all cases.{" "}
        </p>
      </div>
    </>
  );
}

export default Submitted;
