import { ROUTES } from "./../utils/Constant";
import Home from "./../pages/Home";
import Help from "./../pages/Help";
import Terms from "./../pages/Terms";
import Login from "./../pages/Login";
import Signup from "./../pages/Signup";
import ResetPassword from "./../pages/ResetPassword";
import ForgotPassword from "./../pages/ForgotPassword";
import DataInputs from "../pages/DataInputs";
import Letter from "../pages/Letter";
import Dashboard from "../pages/Dashboard";
import Faqs from "./../pages/Faqs";
import premiumSignup from "./../pages/PremiumSignup";
import UpdateLetter from "./../pages/UpdateLetter";
import GeneratedLetter from "./../pages/GeneratedLetter";
import GrappleLetters from "./../pages/GrappleLetters";
import Pricing from "./../pages/Pricing";
import LetterDetail from "./../pages/LetterDetail";
import CheckLetter from "./../pages/CheckLetter";
import Profile from "./../pages/Profile";
import SubscriptionCancelled from "./../pages/SubscriptionCancelled";
import ChooseLetter from "../pages/ChooseLetter";
import SetPassword from "../pages/SetPassword";
import LawyerAccess from "../pages/LawyerAccess";
import MoreDetails from "../pages/MoreDetails";
import PreviewLetter from "../pages/PreviewLetter";
import CustomerAccess from "../pages/CustomerAccess";
import Thankyou from "../pages/Thankyou";
import CclSign from "../pages/CclSign";
import CclComplete from "../pages/CclComplete";
import ClientReviewLetter from "../pages/ClientReviewLetter";
import SubmittedSuccessfully from "../pages/SubmittedSuccessfully";
import Submitted from "../pages/Submitted";
import InternalReviewLetter from "../pages/InternalReviewLetter";
import MeritAssessment from "../pages/MeritAssessment";

export const PublicRoutes = [
  {
    path: ROUTES.LANDING_PAGE.URL,
    component: Home,
  },
  {
    path: ROUTES.DATAINPUTS.URL,
    component: DataInputs,
  },
  {
    path: ROUTES.MOREDETAILS.URL,
    component: MoreDetails,
  },
  {
    path: ROUTES.PREVIEWLETTER.URL,
    component: PreviewLetter,
  },
  {
    path: ROUTES.CUSTOMERACCESS.URL,
    component: CustomerAccess,
  },
  {
    path: ROUTES.LETTER.URL,
    component: Letter,
  },
  {
    path: ROUTES.RESET_PASSWORD.URL,
    component: ResetPassword,
  },
  {
    path: ROUTES.SET_PASSWORD.URL,
    component: SetPassword,
  },
  {
    path: ROUTES.LOGIN.URL,
    component: Login,
  },
  {
    path: ROUTES.SIGNUP.URL,
    component: Signup,
  },
  {
    path: ROUTES.PREMIUMSIGNUP.URL,
    component: premiumSignup,
  },
  {
    path: ROUTES.FORGOTPASSWORD.URL,
    component: ForgotPassword,
  },
  {
    path: ROUTES.HELP_PAGE.URL,
    component: Help,
  },
  {
    path: ROUTES.TERMS_PAGE.URL,
    component: Terms,
  },
  {
    path: ROUTES.UPDATELETTER.URL,
    component: UpdateLetter,
  },
  {
    path: ROUTES.GENERATEDLETTER.URL,
    component: GeneratedLetter,
  },
  {
    path: ROUTES.GRAPPLELETTERS.URL,
    component: GrappleLetters,
  },
  {
    path: ROUTES.PRICING.URL,
    component: Pricing,
  },
  {
    path: ROUTES.LETTERDETAIL.URL,
    component: LetterDetail,
  },
  {
    path: ROUTES.CHECKLETTER.URL,
    component: CheckLetter,
  },
  {
    path: ROUTES.SUBSCRIPTIONCANCELLED.URL,
    component: SubscriptionCancelled,
  },
  {
    path: ROUTES.CHOOSELETTER.URL,
    component: ChooseLetter,
  },
  {
    path: ROUTES.LAWYERACCESS.URL,
    component: LawyerAccess,
  },
  {
    path: ROUTES.THANKYOU.URL,
    component: Thankyou,
  },
  {
    path: ROUTES.CCLSIGN.URL,
    component: CclSign,
  },
  {
    path: ROUTES.CCLCOMPLETE.URL,
    component: CclComplete,
  },
  {
    path: ROUTES.CLIENTREVIEWLETTER.URL,
    component: ClientReviewLetter,
  },
  {
    path: ROUTES.SUBMITTEDSUCCESSFULLY.URL,
    component: SubmittedSuccessfully,
  },
  {
    path: ROUTES.SUBMITTED.URL,
    component: Submitted,
  },
  {
    path: ROUTES.INTERNALREVIEWLETTER.URL,
    component: InternalReviewLetter,
  },
  {
    path: ROUTES.MERITASSESSMENT.URL,
    component: MeritAssessment,
  },
];

export const PrivateRoutes = [
  {
    path: ROUTES.DASHBOARD.URL,
    component: Dashboard,
  },
  {
    path: ROUTES.FAQs.URL,
    component: Faqs,
  },
  {
    path: ROUTES.PROFILE.URL,
    component: Profile,
  },
];

const RoutesList = [...PublicRoutes, ...PrivateRoutes];

export default RoutesList;
